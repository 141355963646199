@mixin heading-block-h1 {
  @include font-special;

  display: inline-block;
  font-size: 3.7rem;
  margin-bottom: 1rem;

  @include media-breakpoint-up(sm) {
    font-size: 4.5rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 5.5rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 6rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 7rem;
  }
}
