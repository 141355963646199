// start - font
@mixin readability-text-shadow {
  text-shadow: 0 0 3px rgb(0 0 0 / 50%);
}

@mixin font-bold {
  font-weight: $font-weight-bold;
}

@mixin font-uppercase {
  text-transform: uppercase;
  letter-spacing: 0.04rem;
}

@mixin font-uppercase-bold {
  @include font-uppercase;
  @include font-bold;
}

@mixin font-special {
  font-family: $theme-font-regular, $font-family-sans-serif;
}

@mixin font-faint {
  font-style: italic;
  color: $gray-800;
  font-size: 0.9rem;
}

@mixin font-content-title($withMargin: true) {
  font-size: $font-content-title-size;
  font-weight: $font-content-title-weight;
  @include font-uppercase;

  @if $withMargin == true {
    margin-bottom: 1rem;
  }
}

// end - font

// start - headings
@import 'headings';
@mixin heading-common {
  @include font-bold;
}

@mixin heading-h2 {
  font-size: 2.2rem;
  margin-bottom: 1rem;

  @include media-breakpoint-up(sm) {
    font-size: 3rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 4rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 4.2rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 5.5rem;
  }
}

// end - headings

// start - jumbo title
@mixin jumbo-title {
  font-family: $theme-font-bold;
  font-size: 4rem;
  @include font-bold;

  @include media-breakpoint-up(md) {
    margin-bottom: 2.5rem;
    font-size: 5rem;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 4rem;
    font-size: 8rem;
  }
}

@mixin jumbo-subtitle {
  font-size: 1.1rem;
  @include font-uppercase-bold;

  @include media-breakpoint-up(sm) {
    font-size: 1.2rem;
  }
}

// end - jumbo title

// booking wizard
@mixin booking-wizard-item-label {
  @include font-uppercase;
  @include font-bold;
}
// end - booking wizard
