$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/kultour/';
@import 'styles/helpers/global_variables';
.resetBtn {
  color: $filter-form-color;
  white-space: nowrap;
  font-style: italic;
  font-size: 0.85rem;
  transition: all $default-animation-speed ease-in-out;

  &:hover {
    color: $filter-lead-form-submit-btn-hover-color;
  }
}
