$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/kultour/';
@import 'styles/helpers/global_variables';

.root {
  @include content-block;
  @include floating-text;

  border: none;
  padding: 0;
  box-shadow: rgb(0 0 0 / 20%) 0 0 1px -2px, rgb(0 0 0 / 14%) 0 0 2px 0, rgb(0 0 0 / 12%) 0 0 5px 0;

  ul {
    @include branded-list;
  }

  &.right {
    .ribbon {
      left: 0;
    }
  }

  &.withLink .body {
    padding-bottom: 6.5rem !important;
  }

  .body {
    @include card-body;

    .subTitle {
      font-weight: normal;
      font-size: 1.2rem;
      margin-top: -0.6rem;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
  }

  @include media-breakpoint-up(lg) {
    .imgWrap {
      & > img {
        position: absolute;
        object-fit: cover;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  .ribbon {
    @include branded-button-font;

    // position
    position: absolute;
    bottom: 23px;
    left: 0;

    // no left side shadow
    overflow: hidden;
    padding: 5px 27px 6px 0;

    @include media-breakpoint-up(lg) {
      left: 50%;
    }
  }
}
