$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/kultour/';
@import 'styles/helpers/global_variables';

$fontColor: #fff;

.root {
  h2 {
    text-align: center;
  }

  .title {
    font-size: 1.1rem;
    line-height: 2.4rem;
    @include media-breakpoint-up(lg) {
      margin: $filter-form-title-margin;
      text-align: right;
    }
    @include font-uppercase;
  }

  .inputs {
    @include media-breakpoint-up(lg) {
      margin: $filter-form-inputs-margin;
    }
  }

  .filterOptionsWrap {
    display: none;

    &.active {
      display: block;
    }
  }

  .checkbox {
    label {
      font-size: 0.9rem;
      text-transform: uppercase;
      letter-spacing: 0.04rem;
    }
  }

  // search input
  .input {
    border: 1px solid #212529;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-size: 0.9rem;
      font-style: italic;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      font-size: 0.9rem;
      font-style: italic;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-size: 0.9rem;
      font-style: italic;
    }
  }

  .resultInfo {
    @include font-uppercase;
  }

  .submitWrap {
    @include media-breakpoint-up(lg) {
      margin: $filter-form-submit-margin;
    }

    .resultInfoWrap {
      @include media-breakpoint-down(lg) {
        margin-bottom: 1rem;
      }
      @include media-breakpoint-up(xxl) {
        margin-bottom: 1rem;
      }
    }

    @include media-breakpoint-down(lg) {
      justify-content: flex-end;

      .resultInfoWrap {
        flex: 0 0 auto;
        width: auto;
      }
    }
  }

  .resetBtn {
    font-style: italic;
    font-size: 0.85rem;
  }
}
