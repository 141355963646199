@mixin readability-text-shadow {
  text-shadow: 0 0 1px rgb(0 0 0 / 90%);
}

@mixin font-special {
  font-family: $theme-font-regular, $font-family-sans-serif;
}

@mixin heading-common {
  @include font-special;
}

@mixin heading-h2 {
  font-size: 2.8rem;
  margin-bottom: 1rem;

  @include media-breakpoint-up(sm) {
    font-size: 4.5rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 5.5rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 6rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 7rem;
  }

  @media print {
    font-size: 4.5rem;
    padding-top: 0.75rem;
  }
}

@mixin heading-block-subtitle {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  @include font-uppercase-bold;
}

@mixin jumbo-title {
  @include font-special;
  font-size: 5rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 2.5rem;
    font-size: 7rem;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 4rem;
    font-size: 12rem;
  }

  @media print {
    margin-bottom: 2.5rem;
    font-size: 7rem;
  }
}

@mixin teaser-flag {
  line-height: 2.5rem;
  height: 2.5rem;
  @include font-uppercase-bold;

  &::before {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    border: 1.25rem solid $signal;
    z-index: -1;
    left: -1.75rem;
    border-left-color: transparent;
  }
}

@mixin branded-button-font {
  @include font-uppercase-bold;
}

@mixin image-block-font {
  @include readability-text-shadow;
  @include font-special;

  // font size
  font-size: 5rem;
  @include media-breakpoint-up(md) {
    font-size: 7rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 9rem;
  }
}

@mixin travel-highlights-list {
  @include branded-list(0.4rem);
  @include font-uppercase;

  li {
    line-height: 1.5rem;
    margin-bottom: 0.2rem;
  }
}
