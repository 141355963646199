@import 'typography';
@import 'elements';
@import 'components';

@mixin font-title-h3($withMargin: true) {
  font-size: 2rem;
  @include font-uppercase-bold;

  @if $withMargin == true {
    margin-bottom: 1rem;
  }
}

@mixin font-title-h4($withMargin: true) {
  font-size: 1.3rem;
  @include font-uppercase-bold;

  @if $withMargin == true {
    margin-bottom: 0.5rem;
  }
}

@mixin font-content-subtitle($withMargin: true) {
  font-size: 1rem;
  @include font-uppercase-bold;

  @if $withMargin == true {
    margin-bottom: 0.4rem;
  }
}

@mixin icon-size($size, $unit: 'rem') {
  width: $size + unquote($unit);
  height: $size + unquote($unit);
}

@mixin card-body {
  padding: 1.3rem 2rem 1.5rem 2rem;
  @include media-breakpoint-up(md) {
    padding: 1.3rem 3rem 1.5rem 3rem;
  }
  //padding: 2rem 1.8rem 2rem;
  //
  //@include media-breakpoint-up(md) {
  //  padding: 2.5rem 3rem 3rem;
  //}
}

@mixin card-title {
  line-height: 1;
  font-size: 1.8rem;

  @include media-breakpoint-up(md) {
    font-size: 2rem;
  }
}

@mixin table-heading {
  border-bottom: 1px dashed $black;
  font-size: 0.9em;
  font-style: italic;
  color: $gray-800; // $gray-600
  padding: 0.2rem 0; // 0.5rem 0
  background-color: #efefef;
}

@mixin error-decent {
  color: $danger;
  font-size: 0.9rem;
  font-style: italic;
}

@mixin info-decent {
  color: $info;
  font-size: 0.9rem;
  font-style: italic;
}

@mixin no-select {
  user-select: none;
}

@mixin booking-subgroup {
  margin-bottom: $booking-group-spacing;
}

@mixin display-linebreak() {
  white-space: pre-line;
}

@mixin content-block() {
  margin-bottom: 3rem;
}

@mixin floating-text() {
  line-height: 1.35;
}
