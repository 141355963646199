// scss-docs-start page-header-variables
$header-content-padding-x: 1.25rem !default;
$header-content-padding-x-sm: 2.5rem !default;
$header-content-padding-x-xl: 2.5rem !default;
$header-content-padding-y: 1.5rem !default;
$header-slider-content-padding-bottom: 2.5rem !default;
// scss-docs-end page-header-variables

// scss-docs-start main-nav
$headerHeight: 87px !default;
$navHeight: 87px !default;
$navPadding: 1rem 0 !default;
// scss-docs-end main-nav

// scss-docs-start filter
$filter-form-bg-color: $white !default;
$filter-form-color: $font-color-default !default;
$filter-form-title-margin: 0 !default;
$filter-form-inputs-margin: 0 !default;
$filter-form-submit-margin: 0 !default; // lead-form
$filter-form-info-margin: 0 !default; // filter-nav (filter page)
$filter-form-input-bg-color: transparent !default;
$filter-form-input-border-color: #212529 !default;
$filter-form-input-placeholder-color: $input-placeholder-color !default;
$filter-form-input-placeholder-hover-color: #212529 !default;
$filter-form-input-text-color: #212529 !default;
$filter-form-input-chevron-color: encodecolor(#6c757d) !default;
$filter-form-input-chevron-hover-color: encodecolor(#343a40) !default;
$filter-form-calendar-year-label-bg-color: $gray-400 !default;
$filter-form-calendar-month-hover-bg-color: $gray-300 !default;
$filter-form-calendar-month-active-bg-color: $signal !default;
$filter-form-calendar-month-disabled-opacity: 1 !default;
$filter-form-badge-bg-color: $gray-600 !default;
$filter-lead-form-submit-btn-margin-top: 0.5rem !default;
$filter-lead-form-submit-btn-font-family: $theme-font-regular !default;
$filter-lead-form-submit-btn-font-size: 5rem !default;
$filter-lead-form-submit-btn-color: $font-color-default !default;
$filter-lead-form-submit-btn-hover-color: $signal !default;
// scss-docs-end filter

// scss-docs-start heading-block
$cms-heading-block-margin-bottom: inherit !default;
// scss-docs-end heading-block

// scss-docs-start contact-banner
$contact-banner-bg-color: $primary !default;
$contact-banner-color: $white !default;
// scss-docs-end contact-banner

// scss-docs-start newsletter-block
$newsletter-block-font-family: inherit !default;
$newsletter-block-title-color: inherit !default;
$newsletter-block-color: $tertiary !default;
$newsletter-block-color-hover: $black !default;
// scss-docs-end newsletter-block

// scss-docs-start travel-program-block
$travel-itinerary-block-margin-top: 0 !default;
$travel-itinerary-block-margin-bottom: 3rem !default;
$travel-itinerary-block-program-wrap-max-height: initial !default;
// scss-docs-end travel-program-block

// scss-docs-start travel-program-block
$travel-extension-teaser-branded-list-top: 0.1rem !default;
// scss-docs-end travel-program-block

// scss-docs-start travel-pricing-block
$pricing-block-margin-top: 0 !default;
$pricing-block-margin-bottom: 3rem !default;
$pricing-block-title-margin-bottom: 3rem !default;
$pricing-block-info-outer-padding: 0 0 0 0 !default;
$pricing-block-info-outer-margin: 0 0 3rem 0 !default;
$pricing-block-info-outer-margin-print: 0 0 1rem 0 !default;
$pricing-block-info-inner-padding: 0 0 0 0 !default;
$pricing-block-info-inner-padding-print: $pricing-block-info-inner-padding !default;
$pricing-block-info-pricing-info-color: auto !default;
$pricing-block-services-outer-padding: 0 0 0 0 !default;
$pricing-block-services-outer-padding-print: $pricing-block-services-outer-padding !default;
$pricing-block-services-ul-margin: 0 0 3rem 0 !default;
// scss-docs-end travel-pricing-block

// scss-docs-start travel-guide-block
$travel-guide-block-margin-top: 0 !default;
$travel-guide-block-margin-bottom: 3rem !default;
$travel-guide-block-margin-bottom-print: 1.5rem !default;
$travel-guide-block-title-margin-bottom: 0 !default;
// scss-docs-end travel-guide-block

// scss-docs-start travel-teams-block
$teams-block-margin-top: 0 !default;
$teams-block-margin-bottom: 3rem !default;
$teams-block-title-margin-bottom: $travel-guide-block-title-margin-bottom !default;
// scss-docs-end travel-teams-block

// scss-docs-start travel-accommodation-block
$travel-accommodation-block-margin-top: 0 !default;
$travel-accommodation-block-margin-bottom: 3rem !default;
// scss-docs-end travel-accommodation-block

// scss-docs-start travel-gallery-block
$travel-gallery-block-margin-top: 0 !default;
$travel-gallery-block-margin-bottom: 3rem !default;
// scss-docs-end travel-gallery-block

// scss-docs-start init-booking
$init-booking-base-price-label-font-family: inherit !default;
$init-booking-base-price-label-font-size: 0.9rem !default;
$init-booking-base-price-from-font-family: inherit !default;
$init-booking-base-price-from-font-size: 0.7rem !default;
$init-booking-base-price-price-font-family: inherit !default;
$init-booking-base-price-price-font-size: 2rem !default;
// scss-docs-start init-booking

// scss-docs-start booking-summary
$booking-summary-primary-color: auto !default;
$booking-summary-secondary-color: $gray-700 !default;
// scss-docs-end booking-summary

// scss-docs-start star-rating
$star-color: rgb(255, 215, 0) !default;
// scss-docs-end star-rating

// scss-docs-start
// scss-docs-end
